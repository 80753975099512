import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_es-es",
  "shopName": "lusini",
  "locale": "es-es",
  "rootCategoryId": "EMG",
  "orderMinimumB2C": 35,
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/ayuda-y-servicio/contacto/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "es-ES",
    "country": "ES"
  },
  "slugifyLocale": "es",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "es-es",
      "hrefLang": "es"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-es.svg",
      "newsletter_intentlayer_picture": "https://res.cloudinary.com/lusini/image/upload/v1724665736/application/NewsletterPopupAB/discount-reduction-es-es.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "initialCountryStateId": "d2c71d0f909b48c4b2c104012fd75fb9",
      "accessKey": {
        "b2b": "SWSC3EAQD84E70DOCRMFYLFY9X",
        "b2c": "SWSCTYVWE3KIVYZQUASMMBN1OI"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/es-es/b2b",
        "b2c": "https://www.lusini.com/es-es/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "e3241f26e68894b7351374710059355e2181541cd5ef10353a15507d5d1e859b",
      "campaignID": "9879543"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "XD4F5B33E8E55A115AD6C3B6F33A4DE10",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "freshchat": {
      "token": "77c43185-76a1-414d-a4c2-23079e3b4967",
      "host": "https://lusini.freshchat.com",
      "widgetUuid": "72010907-f026-437a-9371-debefca0f003",
      "locale": "es"
    },
    "zenloop": {
      "id": "TWpnelpUY3pZVGt0WmpZMVpDMDBZbVZrTFRnME5qVXRObVl4TXpReE5qUmhNemxo"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_es_ES_products",
    "productsPriceAsc": "prod_lusini_es_ES_products_priceAsc",
    "productsPriceDesc": "prod_lusini_es_ES_products_priceDesc",
    "categories": "prod_lusini_es_ES_categories",
    "series": "prod_lusini_es_ES_series",
    "productsSuggestion": "prod_lusini_es_ES_products_query_suggestions",
    "productsLatest": "prod_lusini_es_ES_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Blanco": "white",
    "Negro": "black",
    "Gris": "grey",
    "Plateado": "silver",
    "Marrón": "brown",
    "Azul": "blue",
    "Multicolor": "multicolor",
    "Rojo": "red",
    "Beige": "beige",
    "Verde": "green",
    "Transparente": "transparent",
    "Amarillo": "yellow",
    "dorado": "gold",
    "Naranja": "orange",
    "Rosa/Lila": "lilac"
  },
  "features": {
    "notracking": true,
    "devMode": false,
    "trustShop": true,
    "freshchat": true,
    "zenloop": true,
    "qrCode": false,
    "b2c": true,
    "b2cHomePage": true,
    "userLikeWidgetUrl": "",
    "notificationBar": true,
    "showNewsletterIntentLayer": true,
    "customizableUrl": "https://www.lusini.com/es-es/ayuda-y-servicio/servicios/personalizacion/",
    "showCartInfoboxFrom": "2024-12-12T08:00:00",
    "showCartInfoboxTo": "2025-01-10T01:00:00"
  },
  "abTests": {},
  "showTelephoneContactOnFailedCheckoutThreshold": 0
}
export default config